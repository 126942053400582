/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// JavaScript to be fired on all pages

				// ##########
				// hash link overwrite
				// ##########

				$('.main_nav > li > a').on('click', function (e) {
					if ($(this).attr('href') === '#') {
						e.preventDefault();
					}
				});

				// ##########
				// mobile nav toggle
				// ##########

				var mobile_nav_toggle = $('.mobile_nav_toggle'),
					mobile_nav_close = $('.mobile_nav_close'),
					main_nav_container = $('.header_main__main_nav'),
					nav_links = $('.main_nav > li > a, .main_nav > li > ul > li > a');

				mobile_nav_toggle.on('click', function (e) {
					e.preventDefault();
					e.stopPropagation();
					main_nav_container.slideToggle();
				});

				mobile_nav_close.on('click', function (e) {
					e.preventDefault();
					main_nav_container.slideUp();
				});

				nav_links.on('click', function (e) {
					e.stopPropagation();
				});

				$(document).on('click', function () {
					if (mobile_nav_toggle.is(':visible')) {
						main_nav_container.slideUp();
					}
				});

				// ##########
				// animations
				// ##########

				// ##########
				// init wow library
				var wow_animations = new WOW({
					offset: 200
				});
				wow_animations.init();

				// ##########
				// interior svg animations

				if ($('.interior_callouts').length) {

					// animate svgs
					new Vivus('interior_svg_schedule', { duration: 50 });
					new Vivus('interior_svg_payment', { duration: 50 });
					new Vivus('interior_svg_treatment', { duration: 50 });
				}

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'page_template_template_home': {
			init: function () {

				// ##########
				// animations
				// ##########

				// init scroll magic controller
				var sm_controller = new ScrollMagic.Controller();

				// ##########
				// svg animations
				new Vivus('svg_schedule', { duration: 80 });
				new Vivus('svg_payment', { duration: 80 });
				new Vivus('svg_treatment', { duration: 80 });

				// ##########
				// count up

				var reviews_count_up_scene = new ScrollMagic.Scene({
					triggerElement: '#reviews_count_up',
					reverse: false
				}).addTo(sm_controller);

				reviews_count_up_scene.on('enter', function (event) {
					var reviews_count_up = $('#reviews_count_up'),
						reviews_count_up_number = reviews_count_up.attr('data-reviewstocount');

					var reviews_count_up_anim = new CountUp('reviews_count_up', 0, reviews_count_up_number);

					if (!reviews_count_up_anim.error) {
						reviews_count_up_anim.start();
					} else {
						console.error(reviews_count_up_anim.error);
					}
				});
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},

		// default template
		'page_template_default': {
			init: function () {

			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
